import React, { useState, useRef, useEffect } from "react";

interface CustomTooltipProps {
  children: React.ReactNode;
  text: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseEnter = () => setIsVisible(true);
    const handleMouseLeave = () => setIsVisible(false);

    const container = containerRef.current;
    if (container) {
      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (container) {
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const tooltip = tooltipRef.current;
      const container = containerRef.current;
      if (tooltip && container) {
        const containerRect = container.getBoundingClientRect();
        const tooltipRect = tooltip.getBoundingClientRect();

        const leftOffset = (containerRect.width - tooltipRect.width) / 2;
        tooltip.style.left = `${Math.max(0, leftOffset)}px`;

        const topOffset = -tooltipRect.height - 10;
        tooltip.style.top = `${topOffset}px`;
      }
    }
  }, [isVisible]);

  return (
    <div ref={containerRef} className="relative inline-block">
      {children}
      {isVisible && (
        <div
          ref={tooltipRef}
          className="absolute z-10 px-2 py-1 text-sm text-REPEOPLE-normal-text bg-REPEOPLE-light-gray rounded-md whitespace-nowrap transition-opacity duration-300"
          style={{
            opacity: 0.9,
            pointerEvents: "none",
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
