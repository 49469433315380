import { logException } from "../utils/logger";

const streamStartTag = "[[START]]";
const streamEndTag = "[[END]]";

export interface Source {
  title: string;
  url: string;
  quote: string;
}

export interface PipelineResponse {
  text_response: string;
  sources: Source[];
}

export class PipelineService {
  static async ragStream(
    query: string,
    textCallback: (text: string) => void,
    sourcesCallback: (sources: Source[]) => void,
    requestIdCallback: (requestId: string) => void,
    agentIdCallback: (agentId: string) => void,
    contextId: string,
    metadata: string,
  ): Promise<{ pipelineResponse: PipelineResponse }> {
    let requestId = "-1";
    let agentId = "";
    try {
      /* const url = `${import.meta.env.VITE_SEARCH_SERVICE_URL}`;

      const body = {
        query,
        metadata,
        context_id: contextId,
        stream: "true",
      }; 

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearear ${import.meta.env.VITE_SEARCH_SERVICE_KEY}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Failed ragStream: ${response.statusText}`);
      }
        */

      const url = `${
        import.meta.env.VITE_SEARCH_SERVICE_URL
      }?query=${encodeURIComponent(query)}&stream=true&context_id=${contextId}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${import.meta.env.VITE_SEARCH_SERVICE_KEY}`,
        },
      });

      if (!response.body) {
        throw new Error("ReadableStream not yet supported in this browser.");
      }

      agentId = response.headers.get("X-Agent-Id") ?? "";
      agentIdCallback(agentId);

      requestId = response.headers.get("x-request-id") ?? "-1";
      requestIdCallback(requestId);

      if (!response.body) {
        throw new Error("ReadableStream not yet supported in this browser.");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let text_response = "";
      let full_response = "";
      let endResponse = false;
      let done = false;

      do {
        const { value, done: chunkDone } = await reader.read();
        done = chunkDone;

        const chunk = decoder.decode(value, { stream: true });

        const lines = chunk.split("\n");

        for (const line of lines) {
          if (line.trim().startsWith("data:")) {
            const jsonLine = line.replace("data: ", "").trim();
            if (jsonLine) {
              try {
                const jsonChunk = JSON.parse(jsonLine);
                if (
                  jsonChunk.delta &&
                  jsonChunk.delta.message &&
                  jsonChunk.delta.message.content
                ) {
                  const content = jsonChunk.delta.message.content;
                  full_response += content;
                  if (full_response.includes(streamStartTag) && !endResponse) {
                    text_response = full_response
                      .replace(/\\n/g, " \n ")
                      .split(streamStartTag)[1]
                      .split(streamEndTag)[0];

                    textCallback(text_response);
                  }

                  if (full_response.includes(streamEndTag)) {
                    endResponse = true;
                  }
                }
              } catch (e) {
                console.error("Error parsing JSON: ", e);
              }
            }
          }
        }
      } while (!done);

      const pipelineResponse = JSON.parse(
        full_response || "",
      ) as PipelineResponse;
      sourcesCallback(pipelineResponse.sources);

      return { pipelineResponse };
    } catch (error) {
      logException(`Failed ragStream: ${error}`);
      throw error;
    }
  }
}
