import React from "react";

type SearchHeaderProps = {
  children?: React.ReactNode;
};

const SearchHeader: React.FC<SearchHeaderProps> = ({ children }) => {
  return (
    <h1
      className={`text-REPEOPLE-dark-blue text-left md:text-[40px] text-[24px] md:leading-[48px] leading-[32px]`}
    >
      {children}
    </h1>
  );
};

export default SearchHeader;
