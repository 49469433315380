// SearchBar.tsx

import React, { useRef, useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import logoAIFindr from "../../assets/Logo-AIFindr.svg";
import { useSearch } from "../../context/SearchContext";
import { useTranslation } from "react-i18next";

interface SearchBarProps {
  queryDisplayMode: "hideSuggestedQueries" | "responsePage" | "searchPage";
  isInternalSearch: boolean;
  isLoading?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  queryDisplayMode,
  isInternalSearch,
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const { handleSearch } = useSearch();
  const [search, setSearch] = useState("");
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const suggestedQueriesRef = useRef<HTMLDivElement>(null);
  const disabled = search === "" || isLoading;
  const [placeholderLabel, setPlaceholderLabel] = useState<string>("");
  const [typedPlaceholder, setTypedPlaceholder] = useState<string>("");

  // Estado para manejar el cursor parpadeante
  const [showCursor, setShowCursor] = useState<boolean>(true);

  const labels = [
    t("translation.recommended-1") || "Search products...",
    t("translation.recommended-2") || "Find what you need...",
    t("translation.recommended-3") || "Looking for something specific?",
    t("translation.recommended-4") || "Discover new products...",
    t("translation.recommended-5") || "Explore our catalog...",
  ];

  const getRandomLabel = () => {
    const filteredLabels = labels.filter(
      (label) => label && label.trim().length > 0,
    );
    if (filteredLabels.length === 0) return "Search products...";
    const randomIndex = Math.floor(Math.random() * filteredLabels.length);
    return filteredLabels[randomIndex];
  };

  useEffect(() => {
    if (!isLoading) {
      if (placeholderLabel === "") {
        const initialLabel = getRandomLabel();
        setPlaceholderLabel(initialLabel);
      }
      const interval = setInterval(() => {
        const newLabel = getRandomLabel();
        setPlaceholderLabel(newLabel);
      }, 8000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isLoading]);

  useEffect(() => {
    setTypedPlaceholder("");

    if (!placeholderLabel) return;

    let currentIndex = 0;
    const typingSpeed = 50;
    const typingInterval = setInterval(() => {
      if (currentIndex < placeholderLabel.length) {
        const nextChar = placeholderLabel[currentIndex];
        setTypedPlaceholder((prev) => prev + nextChar);
        currentIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, typingSpeed);

    return () => {
      clearInterval(typingInterval);
      console.log("Cleared typing interval.");
    };
  }, [placeholderLabel]);

  // useEffect para manejar el parpadeo del cursor
  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500); // Cambia la velocidad del parpadeo aquí (500 ms)

    return () => {
      clearInterval(cursorInterval);
    };
  }, []);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const searchType = isInternalSearch ? "internal" : "initial";
    const query = search !== "" ? search : typedPlaceholder;
    console.log(
      `Submitting search query: "${query}" with type: "${searchType}"`,
    );
    handleSearch(query, searchType);
    setSearch("");
    setIsActive(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        queryDisplayMode === "responsePage" &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        suggestedQueriesRef.current &&
        !suggestedQueriesRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
        console.log("Clicked outside the search bar. Set isActive to false.");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      console.log("Removed click outside listener.");
    };
  }, [queryDisplayMode]);

  return (
    <div
      className="relative space-y-2 transition-all duration-300 ease-in-out"
      ref={wrapperRef}
    >
      <div className="flex justify-center items-center w-full mb-7">
        <div className="w-full justify-center items-center space-y-4">
          <form onSubmit={handleSubmit} className="w-full">
            <div className="flex space-x-2 p-0 m-0">
              <span className="flex-grow w-full relative">
                <div
                  className="w-full p-[2px] rounded-full overflow-hidden"
                  style={{
                    background:
                      "linear-gradient(40deg, #001846 35%, #E8C328 65%)",
                  }}
                >
                  <InputText
                    ref={inputRef}
                    id="in"
                    // Concatenar la barra | al placeholder
                    placeholder={typedPlaceholder + (showCursor ? '|' : '')}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onFocus={() => {
                      setIsActive(true);
                      console.log("Input focused. Set isActive to true.");
                    }}
                    className="w-full h-9 pl-10 pr-10 text-REPEOPLE-gray text-md bg-white rounded-full"
                  />
                </div>

                <span className="absolute inset-y-0 left-3 flex items-center z-20">
                  <img
                    src={logoAIFindr}
                    alt="AIFindr Logo"
                    className="h-5 w-5"
                  />
                </span>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
