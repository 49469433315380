import React, { useState } from "react";
import ResponseContent from "../components/organisms/ResponseContent";
import SearchBar from "../components/molecules/SearchBar";
import SearchHeader from "../components/atoms/SearchHeader";
// import UserFeedback from "../components/organisms/UserFeedback";
import { useSearch } from "../context/SearchContext";
import "../index.css";
import CustomAlert from "../components/atoms/CustomAlert";
import { PoweredByAIFindr } from "../assets/PoweredByAIFindr";
import { useTranslation } from "react-i18next";

const ResponsePage: React.FC = () => {
  const { t } = useTranslation();
  const {
    handleSearch,
    currentSearchTerm,
    textResponse,
    isLoading,
    sources,
    duration,
    requestId,
    contextId,
  } = useSearch();

  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState<
    "angry" | "neutral" | "happy" | null
  >(null);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const handleRefreshClick = () => {
    handleSearch(currentSearchTerm, "refresh");
  };

  const handleFeedbackClick = (feedback: "angry" | "neutral" | "happy") => {
    setSelectedFeedback(feedback);
    setIsFeedbackOpen(true);
    // Aquí puedes llamar a tu función para enviar el feedback
    // sendUserFeedbackSelected(requestId, currentSearchTerm, feedback, contextId);
    setIsFeedbackSent(true); // Asumiendo que el feedback se envía aquí
  };

  const handleFeedbackClose = () => {
    setIsFeedbackOpen(false);
  };

  const handleFeedbackChange = (
    feedback: "angry" | "neutral" | "happy" | null,
  ) => {
    setSelectedFeedback(feedback);
  };

  return (
    <div
      style={{ backgroundColor: "#FFFFFF" }}
      className="flex flex-col w-full min-h-screen relative items-center"
    >
      <div className="flex-grow flex w-full flex-col max-w-2xl px-6 justify-start pb-40 my-16 space-y-8">
        <div className="flex flex-col space-y-4">
          <SearchHeader>{currentSearchTerm}</SearchHeader>
        </div>
        <ResponseContent
          textResponse={textResponse}
          sources={sources}
          isLoading={isLoading}
          handleRefreshClick={handleRefreshClick}
          query={currentSearchTerm}
          requestId={requestId}
          contextId={contextId}
        />

        {isLoading && (
          <div className="flex justify-center items-center mx-auto max-w-[4rem] lg:my-48">
            <img src="simpleSpinner.gif" alt="loading" />
          </div>
        )}

      </div>

      <div className="fixed bottom-0 w-full z-30 flex flex-col items-center">
        {!isLoading && !isFeedbackSent && (
          <div className="w-full  ">
            <div className="flex flex-col items-center bg-white p-5 space-y-2">
              <h3 className="text-REPEOPLE-dark-blue text-[16px]">
                {t("translation.feedback-title")}
              </h3>
              <div className="flex space-x-4">
                <div
                  onClick={() => handleFeedbackClick("angry")}
                  className="cursor-pointer"
                >
                  <span
                    role="img"
                    aria-label="angry"
                    className={
                      selectedFeedback === "angry" ? "selected" : "not-selected"
                    }
                  >
                    😢
                  </span>
                </div>
                <div
                  onClick={() => handleFeedbackClick("neutral")}
                  className="cursor-pointer"
                >
                  <span
                    role="img"
                    aria-label="neutral"
                    className={
                      selectedFeedback === "neutral" ? "selected" : "not-selected"
                    }
                  >
                    🙂
                  </span>
                </div>
                <div
                  onClick={() => handleFeedbackClick("happy")}
                  className="cursor-pointer"
                >
                  <span
                    role="img"
                    aria-label="happy"
                    className={
                      selectedFeedback === "happy" ? "selected" : "not-selected"
                    }
                  >
                    😍
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="w-full">
          <div className="max-w-[1200px] pt-3 pb-8 px-6 bg-white min-[1200px]:rounded-[24px] mx-auto">
            <div className="max-w-[604px] mx-auto">
              <SearchBar
                queryDisplayMode="responsePage"
                isInternalSearch={true}
                isLoading={isLoading}
              />
            </div>
            <footer className="flex justify-center items-center w-full py-4 text-white">
              <PoweredByAIFindr width="124" height="14" />
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsePage;
