import React from "react";
import ReactMarkdown from "react-markdown";
import { Source } from "../../services/PipelineService";
import CustomTooltip from "../molecules/CustomTooltip";
import { useTranslation } from "react-i18next";
/* import {
  sendResponseLinkClick,
  sendSourceLinkClick,
} from "../../utils/analyticsEvents"; */

interface ResponseContentProps {
  textResponse: string;
  sources: Source[];
  isLoading?: boolean;
  handleRefreshClick: () => void;
  query: string;
  requestId: string;
  contextId: string;
}

const removeSpecificParams = (url: URL, setRfidValue: string): string => {
  const paramsToRemove = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "pcid",
    "rfid",
  ];

  paramsToRemove.forEach((param) => {
    url.searchParams.delete(param);
  });

  url.searchParams.set("rfid", setRfidValue);

  url.search = url.search.replace(/%3A/g, ":");

  return url.toString();
};

// Add query, requestId and contextId in ResponseContentProps
const ResponseContent: React.FC<ResponseContentProps> = ({
  textResponse,
  sources,
  isLoading,
  handleRefreshClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full justify-center">
      <div className="max-w-2xl">
        {textResponse && (
          <div className="my-0 flex items-start space-x-3">
            <div className="flex-shrink-0 pt-1">
              <svg
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 148.29 155.06"
                width="20"
                height="20"
              >
                <path
                  fill="#001846"
                  d="M148.29,142.2l-22.31-23.16c8.57-10.08,13.76-23.09,13.76-37.31,0-20.71-11.22-39.95-29.29-50.21-4.45-2.53-10.11-.97-12.65,3.48-2.53,4.45-.97,10.1,3.48,12.63,12.28,6.98,19.9,20.03,19.9,34.1,0,21.62-17.67,39.22-39.38,39.22-13.84,0-26.84-7.38-33.91-19.25-2.62-4.4-8.31-5.84-12.7-3.23-4.4,2.62-5.84,8.3-3.23,12.71,10.4,17.47,29.5,28.32,49.85,28.32,11,0,21.27-3.07,30.04-8.4l23.09,23.95,13.35-12.86Z"
                />
                <path
                  fill="#001846"
                  d="M82.25,42.77v-3.3c-10.46,0-20.51-4.17-27.91-11.57-7.4-7.4-11.55-17.44-11.55-27.9h-3.3c0,5.19-1.02,10.32-3.01,15.11-1.99,4.79-4.89,9.15-8.56,12.8-3.67,3.67-8.03,6.57-12.81,8.55-4.8,1.99-9.94,3.01-15.12,3.01v3.3c10.47,0,20.49,4.17,27.88,11.57,7.39,7.4,11.55,17.43,11.56,27.89h3.3c.01-10.46,4.17-20.48,11.58-27.87,7.39-7.39,17.43-11.56,27.89-11.57h.04Z"
                />
              </svg>
            </div>
            <ReactMarkdown
              className="prose font-[400] text-[16px] text-REPEOPLE-normal-text"
              components={{
                a: ({ href, ...props }) => {
                  let modifiedHref = href;

                  if (href) {
                    try {
                      const url = new URL(href);
                      removeSpecificParams(url, "buscador:respuesta:pilotoIA");
                      modifiedHref = url.toString();
                    } catch (error) {
                      console.error("URL inválida en ReactMarkdown:", href);
                      modifiedHref = "#";
                    }
                  }

                  return (
                    <a
                      {...props}
                      href={modifiedHref}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {props.children}
                    </a>
                  );
                },
              }}
            >
              {textResponse}
            </ReactMarkdown>
          </div>
        )}
        <div className="pt-8 text-sm text-REPEOPLE-dark-gray space-x-1 space-between flex items-center justify-between">
          <span className="flex items-center space-x-2">
            <a
              href="https://aifindr.ai/comofuncionaaifindr"
              target="_blank"
              rel="noopener noreferrer"
              className="pt-0.5"
            >
              <i className="pi pi-info-circle hover:text-REPEOPLE-gray-border transition-colors" />
            </a>
            <span>{t("translation.response-generated")}</span>
          </span>
          {!isLoading && (
            <CustomTooltip text="Regenerar respuesta">
              <i
                onClick={handleRefreshClick}
                className="pi pi-refresh cursor-pointer hover:text-REPEOPLE-gray-border transition-colors"
              ></i>
            </CustomTooltip>
          )}
        </div>
        {sources.length > 0 && (
          <div className="mt-12 mb-4 font-bold text-[14px] text-REPEOPLE-normal-text uppercase">
            {t("translation.fonts")}
          </div>
        )}
        {sources
          .filter(
            (source, index, self) =>
              index === self.findIndex((e) => e.url === source.url),
          )
          .slice(0, 5)
          .map((result, index) => {
            let urlString = result.url;
            let url;

            try {
              url = new URL(urlString);
              removeSpecificParams(url, "buscador:fuente:pilotoIA");
              urlString = url.toString();
            } catch (error) {
              urlString = "";
            }

            if (!urlString) {
              return (
                <div
                  key={index}
                  className="items-center py-1 space-x-2 text-[16px] text-REPEOPLE-dark-blue underline decoration-[#black]"
                >
                  <a
                    href="https://repeople.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pt-0.5"
                  >
                    <span>Repeople - Website</span>
                  </a>
                </div>
              );
            }

            return (
              <a
                key={index}
                href={urlString}
                target="_blank"
                rel="noopener noreferrer"
                /* onClick={() =>
          sendSourceLinkClick(
            query,
            requestId,
            url.toString(),
            result.title,
            contextId,
          )
        } */
              >
                <div className="items-center py-1 hover:underline space-x-2 text-[16px] text-REPEOPLE-dark-blue underline decoration-[#001846]">
                  <span>{result.title}</span>
                  <i className="pi text-sm pi-arrow-right" />
                </div>
              </a>
            );
          })}
      </div>
    </div>
  );
};

export default ResponseContent;
